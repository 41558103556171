class AddShowcaseRequest {

  constructor(type, headerTitle, headerVisible, headerRightTitle, headerRightVisible, headerRightShopifyCollectionId) {
    this.type = type;
    this.headerTitle = headerTitle;
    this.headerVisible = headerVisible;
    this.headerRightTitle = headerRightTitle;
    this.headerRightVisible = headerRightVisible;
    this.headerRightShopifyCollectionId = headerRightShopifyCollectionId;
  }

  /**
   * @param body
   * @return {object}
   */
  toParamsForAdd(body) {

    return {
      type: this.type,
      header: {
        title: this.headerTitle,
        visible: this.headerVisible,
        right: {
          title: this.headerRightTitle,
          visible: this.headerRightVisible,
          data: {
            shopify_collection_id: this.headerRightShopifyCollectionId
          }
        }
      },
      body: body
    }


    // return {
    //   type: this.type,
    //   header_title: this.headerTitle,
    //   header_visible: this.headerVisible,
    //   header_right_type: this.headerRightType,
    //   header_right_title: this.headerRightTitle,
    //   header_right_visible: this.headerRightVisible,
    //   header_right_shopify_collection_id: this.headerRightShopifyCollectionId,
    //   ratio: this.ratio,
    // };
  }
}

module.exports = AddShowcaseRequest;
