const AddShowcaseRequest = require('../../AddShowcaseRequest')

class AddVerticalRequest extends AddShowcaseRequest {

  /**
   *
   * @param ratio
   * @param columnCount
   * @return {AddVerticalRequest}
   */
  setBody(ratio, columnCount) {
    this.ratio = parseFloat(ratio)
    this.columnCount = parseInt(columnCount)
    return this
  }

  /**
   * @return {object}
   */
  toParams() {
    return this.toParamsForAdd({ratio: this.ratio, column_count: this.columnCount})
  }
}

module.exports = AddVerticalRequest
