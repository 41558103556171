<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="title">{{ t('title') }}
            <span v-if="isHeaderTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_title"
            type="text"
            name="title"
            id="title"
            :disabled="!state.header_visible"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="subTitle">{{ t('rightTitle') }}
            <span v-if="isHeaderRightTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_right_title"
            type="text"
            name="subTitle"
            id="subTitle"
            :disabled="!state.header_visible || !state.header_right_visible"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          v-model="state.header_right_shopify_collection_id"
          :items="collections"
          :disabled="!state.header_visible || !state.header_right_visible"
          :label="t('headerRightShowcaseCollections')"
          :required="isHeaderRightShopifyCollectionIdRequired"
        />
      </div>
      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.header_visible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>
      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.header_right_visible"
            :label="t('rightHeaderIsVisible')"
            :disabled="!state.header_visible"
          />
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('columnCount') }}</label>
              <a-radio-group v-model:value="state.column_count">
                <a-radio :value="1">1</a-radio>
                <a-radio :value="2">2</a-radio>
                <a-radio :value="3">3</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="input-wrap">
              <label for="width">{{ t('width') }}
                <span v-if="isWidthRequired" class="required-star">*</span>
              </label>
              <input
                v-model="state.width"
                class="form-control"
                type="number"
                name="width"
                id="width"
              />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="input-wrap">
              <label for="height">{{ t('height') }}
                <span v-if="isHeightRequired" class="required-star">*</span>
              </label>
              <input
                v-model="state.height"
                class="form-control"
                type="number"
                name="height"
                id="height"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a
                :disabled="isFormInvalid"
                @click="onSubmit"
                class="btn btn-success"
              >{{ t('create.create') }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import AddVerticalRequest from '../../../service/RestApi/ShowcaseRequest/Add/AddVerticalRequest'

export default {
  components: { TagsInput, MySwitch },
  props: {
    collections: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { emitter } = useGlobal()
    const collections = ref(props.collections)

    const state = reactive({
      header_title: '',
      header_right_title: '',
      header_right_shopify_collection_id: '',
      header_visible: true,
      header_right_visible: true,
      header_right_type: 'SHOPIFY_COLLECTION',
      column_count: 1,
      width: 380,
      height: 200,
    })

    // Required checkers
    const isHeaderTitleRequired = computed(() => {
      return ! $v.value.header_title[0].$response
    })

    const isHeaderRightTitleRequired = computed(() => {
      return ! $v.value.header_right_title[0].$response
    })

    const isHeaderRightShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.header_right_shopify_collection_id[0].$response
    })

    const isWidthRequired = computed(() => {
      return ! $v.value.width[0].$response
    })

    const isHeightRequired = computed(() => {
      return ! $v.value.height[0].$response
    })

    // Invalid chekers
    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header_title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header_right_title.$error
    })

    const rules = {
      header_title: [requiredIf(() => state.header_visible)],
      header_right_title: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      header_right_shopify_collection_id: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      width: [required],
      height: [required],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    const onSubmit = () => {
      const addVerticalRequest = new AddVerticalRequest(
        'VERTICAL',
        state.header_title,
        state.header_visible,
        state.header_right_title,
        state.header_right_visible,
        state.header_right_shopify_collection_id,
      ).setBody(
        parseFloat((state.width / state.height).toFixed(2)),
        state.column_count,
      ).toParams()

      restApi.showcaseAdd(addVerticalRequest).then((response) => {

        successNotify(t('showcaseCreated'), t('showcaseCreatedDescription'))

        emitter.emit('showcase-item-added', response.getId())
      }).catch((error) => {

        errorNotify(t('showcaseNotCreated'), t('showcaseNotCreatedDescription') + error)

      })
    }

    const { router, restApi, t, successNotify, errorNotify } = useGlobal()

    return {
      state,
      router,
      t,
      successNotify,
      errorNotify,
      collections,
      cancel,
      onSubmit,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,

      isHeaderTitleRequired,
      isHeaderRightTitleRequired,
      isHeaderRightShopifyCollectionIdRequired,
      isWidthRequired,
      isHeightRequired,
    }
  },
}
</script>

<style scoped>
.plus,
.minus {
  padding: 1px 8px;
}

.plus .pi-plus,
.minus .pi-minus {
  font-size: 0.7em;
}

.plusRow {
  background-color: hsl(1, 100%, 99%);
  padding: 0.5em 0;
}

.plusRow + .plusRow {
  margin-top: 0.5em;
}
</style>
